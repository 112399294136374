<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Searching standards and other framework items</h3>
	<img alt="Searching view example image" srcset="/docimages/search-1.png 4x" class="k-help-img float-right">
	<p class="k-no-clear">When viewing a framework, you can use the box in the top-right of the window to <b>search</b> standards and other items in the framework. A <b>“basic search”</b> will find standards whose text includes the terms you enter <i>exactly</i>, following the rules below. You can alternatively execute an <b>“advanced search”</b> by clicking the robot icon (<v-icon small style="margin:-2px 2px 0 2px">fas fa-robot</v-icon>). Here, <span v-html="app_name"/> uses a sophisticated algorithm that involves both matching the literal search terms you enter with the use of a <a href="https://en.wikipedia.org/wiki/Large_language_model" target="_blank">large language neural network model</a> (LLM) to match on the basis of how the <i>meaning</i> of your search terms compares to the meaning of the text in each framework item. (See below for a detailed explanation of the search algorithm)</p>

	<ul class="k-no-clear">
		<li>Use the FILTERS menu to specify which item fields to search (Statement, Code, and/or Notes) and to limit searches to a grade range and/or one or more types of items (e.g. you could search through only the “Elements” in grades 3-5).</li>
		<li><img alt="Search children example image" srcset="/docimages/search-2.png 5x" class="k-help-img float-right">From any <span v-html="link('item_card', 'item card')"></span>, click “SEARCH CHILDREN OF THIS ITEM” to do a search limited to descendents of that “ancestor item” in the framework tree.</li>
		<li>After typing in your search terms, hit the <b>enter</b> key to conduct a basic search, or hold down the <b>CMD</b> (Mac) or <b>ALT</b> (Windows) key while you hit <b>enter</b> to conduct an advanced search.</li>
		<li>Search results will appear in a panel as shown below. Hover over a result to see a quick preview of the item; click the result to see the item in the document tree, as well as the <span v-html="link('item_card', 'item card')"></span>. You can also click the small <v-icon small>fas fa-copy</v-icon> icon on a search result to copy the item’s code and statement.<img alt="Copy item in item card image" srcset="/docimages/search-3.png 3x" class="k-help-img block"></li>
		<li>Tap <b>CMD/ALT + SHIFT + <v-icon small style="margin:-3px 2px 0 1px;" color="#222">fas fa-arrow-circle-left</v-icon> or <v-icon small style="margin:-3px 2px 0 1px;" color="#222">fas fa-arrow-circle-right</v-icon></b> (left/right arrow) to view the previous or next item from the search results.</li>
	</ul>
	<p><b>Notes for basic searches:</b></p>
	<ul>
		<li>All searches are case-insensitive (e.g. “reading” matches “reading” or “Reading”; “RL” matches “RL” or “rl”.</li>
		<li>Unless you use quotes (see below), partial words will be matched (e.g. “read” will match both “read” and “reading”).</li>
		<li>Each standard statement and human-readable code (e.g. “RL.5.1”) will be searched.</li>
		<li>Characters other than letters, numbers, and a small number of punctuation marks will be ignored in search terms. Punctuation marks that <i>can</i> be used in search terms are: “&nbsp;<b>,</b>&nbsp;” (comma), “&nbsp;<b>:</b>&nbsp;” (colon), “&nbsp;<b>;</b>&nbsp;” (semicolon), and “&nbsp;<b>'</b>&nbsp;” (single-quote). (As noted below, some symbols serve as search directives: “&nbsp;-&nbsp;” and “&nbsp;*&nbsp;”.)</li>
	</ul>

	<p class="mt-5"><b>Sample searches illustrating basic search directives:</b></p>

	<v-simple-table><template v-slot:default>
		<thead>
			<tr>
				<th class="k-search-help--search-terms">Enter this in the search box…</th>
				<th>…to find these standards</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td class="k-search-help--search-terms">read</td>
				<td class="k-search-help--search-results">standards that include “read” (or, e.g. “reading”; see note above)</td>
			</tr>
			<tr>
				<td class="k-search-help--search-terms">read writ RL</td>
				<td class="k-search-help--search-results">standards that include “read” AND “writ” AND “RL”</td>
			</tr>
			<tr>
				<td class="k-search-help--search-terms">"read" OR "RL"</td>
				<td class="k-search-help--search-results">standards that include “read” (but not, e.g., “reading”) OR include “RL”</td>
			</tr>
			<tr>
				<td class="k-search-help--search-terms">reading RL OR writing</td>
				<td class="k-search-help--search-results">standards that include (“reading” AND “RL”) OR include “writing”</td>
			</tr>
			<tr>
				<td class="k-search-help--search-terms">"fluid reading"</td>
				<td class="k-search-help--search-results">standards that include the phrase “fluid reading” (that is, the words “fluid” and “reading” in that order; e.g. “fluid writing and reading” would not be matched)</td>
			</tr>
			<tr>
				<td class="k-search-help--search-terms">-writing</td>
				<td class="k-search-help--search-results">standards that DO NOT include “writing”</td>
			</tr>
			<tr>
				<td class="k-search-help--search-terms">topic -"writing projects"</td>
				<td class="k-search-help--search-results">standards that DO include “topic” but do NOT include “writing projects”</td>
			</tr>
			<tr>
				<td class="k-search-help--search-terms">"read*aloud"</td>
				<td class="k-search-help--search-results">standards that include a phrase that starts with “read”, followed by any number of characters, followed by “aloud” (e.g. “read aloud”, “reading aloud”, “reading dialog aloud”)</td>
			</tr>
			<tr>
				<td class="k-search-help--search-terms">RL*5</td>
				<td class="k-search-help--search-results">standards that include “RL”, followed by any number of characters, followed by “5” (e.g. “RL.1.5”, “RL.2.5”, “RL.K.5”)</td>
			</tr>
		</tbody>
	</template></v-simple-table>

	<p class="mt-5"><b>Detailed explanation of the “advanced search” algorithm:</b></p>

	<ul>
		<li><b>Determine universe of possible matches:</b> <span v-html="app_name"/> determines the universe of framework items to search, based on the grade levels, item types, and/or ancestor item you’ve specified.</li>
		<li><b>Find literal matches:</b> For each item, <span v-html="app_name"/> determines whether or not the item’s “Full Statement”, Human-Readable “Code”, and/or “Notes” fields (depending on your setting) matches the literal search terms you’ve entered, following the rules above.</li>
		<li><b>Find LLM matches:</b> <span v-html="app_name"/> runs your search term string through an open-source large language model to produce a 512-dimensional vector that represents the inherent “meaning” of the string, then compares this search term vector to pre-calculated vectors representing the meaning of the full statement for each of the items, using a data analysis routine called the cosine similarity. (Note: the LLM match value is always based on matches with each item’s full statement, regardless of which fields you specify for the literal match search.)</li>
		<li><b>Combine and present results:</b> <span v-html="app_name"/> combines the literal match score and LLM match score for each item into a composite match score, sorts all items with the highest composite scores on top, and presents the search results to you. It will almost always be the case that items that include the literal search terms you entered will appear at the top of your results, and matches to the literal search terms will be <u>underlined</u> in the search results.</li>
	</ul>
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
.k-search-help--search-terms {
	color:#333;
	// font-weight:bold;
	font-size:14px!important;
	white-space:nowrap;
}
.k-search-help--search-results {
	font-size:14px!important;
}
</style>
	